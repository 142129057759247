<template>
  <div class="operation">
    <div class="operation__nav" v-if="menus.length > 1">
      <router-link class="operation__navItem" id="navItem" v-for="(menu, idx) in menus" :key="idx + menu.tag" :to="menu.to">
        <iconic :name="menu.icon" :class="['operation__navIcon', menu.icon]"></iconic>
        {{ menu.tag }}
      </router-link>
    </div>
    <router-view class="operation__content" />
  </div>
</template>

<script>
  export default {
    computed: {
      menus() {
        const menus = [
          { tag: "Asignaciones", to: { name: "assignments" }, icon: "assignment" },
          { tag: "En vivo", to: { name: "occupation" }, icon: "transmission" },
        ];
        if (this.$userData.role === "superadmin") {
          menus.push({ tag: "Conectados", to: { name: "connected" }, icon: "users_connected" }, { tag: "Ocupación habitaciones", to: { name: "statistics" }, icon: "statistics" });
        }
        return menus;
      },
    },
  };
</script>

<style lang="scss">
  .operation {
    @include Flex(column, flex-start, center);
    height: 100%;
    width: 100%;
    gap: $mpadding;
    margin-top: 20px;
    font-size: 14px;
    .router-link-active {
      color: white;
      font-weight: 600;
      background-color: $primary-color;
    }
    &__nav {
      @include Flex(row, flex-start);
      max-width: $tv_width;
      width: 100%;
      color: $primary-color;
      overflow-x: auto;
    }
    &__navItem {
      @include Flex(row);
      height: 36px;
      width: 100%;
      gap: 7px;
      white-space: nowrap;
      padding: 0 10px;
      border: 0.5px solid #d8d8d8;
      border-radius: 9px 9px 0 0;
      &:hover {
        text-decoration: none;
      }
    }
    &__navIcon {
      font-size: 18px;
    }
    .transmission {
      animation: transmission 1s linear infinite;
    }
    @keyframes transmission {
      0% {
        transform: scale(1);
      }
      80% {
        transform: scale(1.4);
      }
      100% {
        transform: scale(1);
      }
    }
    @media screen and (min-width: 675) {
      &__navItem {
        min-width: 215px;
      }
    }
  }
</style>
